const toggleFullScreen = () => {
    const element = document.documentElement;

    if (!document.Fullscreen && !document.webkitFullScreen && !document.msRequestFullscreen) {
        if (element.requestFullScreen) {
            element.requestFullScreen();
        } else if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
        } else if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
            element.msRequestFullscreen();
        }
    } else {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        }
    }
}

// Event-Listener für einen Klick hinzufügen
const fullscreenButton = document.querySelector('.c-slider__fullscreen');

fullscreenButton.addEventListener('click', () => {
    const fullBtn = document.querySelector('.c-btn__fullscreen--full')
    const smallBtn = document.querySelector('.c-btn__fullscreen--small')

    if (document.fullscreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
        // Seite ist bereits im Vollbildmodus, daher den Vollbildmodus verlassen
        if (document.exitFullscreen) {
            document.exitFullscreen();
            smallBtn.classList.remove('is-active');
            fullBtn.classList.add('is-active');
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
            smallBtn.classList.remove('is-active');
            fullBtn.classList.add('is-active');
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
            smallBtn.classList.remove('is-active');
            fullBtn.classList.add('is-active');
        }
    } else {
        // Seite ist nicht im Vollbildmodus, daher in den Vollbildmodus wechseln
        toggleFullScreen();
        fullBtn.classList.remove('is-active');
        smallBtn.classList.add('is-active');
    }
});