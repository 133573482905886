import Swiper, { Navigation, Pagination, EffectFade, Mousewheel } from 'swiper'
import _throttle from "lodash.throttle";
import eventBus from "../utils/eventBus";

const sliderElements = document.querySelectorAll('.c-slider')

if (sliderElements) {
  sliderElements.forEach(sliderEl => {
    let slider;
    let baseOptions = {
      init: false,
      loop: false,
      modules: [Navigation, Pagination, EffectFade, Mousewheel],
      pagination: {
        el: sliderEl.querySelector('.c-slider__pagination'),
        type: 'custom',
        renderCustom: function (swiper, current) {
          let index = 0;
          if(current === 1) {
            return current;
          } else {
            index = index + 2;
            return (index * current - 2) + ' | ' + ((index * current - 2) + 1);
          }
        }
      },
      navigation: {
        nextEl: sliderEl.querySelector('.c-slider__button--next'),
        prevEl: sliderEl.querySelector('.c-slider__button--prev'),
      }
    }
    const { sliderSettings } = sliderEl.dataset
    let additionalSettings = {}

    if (sliderSettings) {
      additionalSettings = JSON.parse(sliderSettings)
    }

    const enableSlider = () => {
      slider = new Swiper(sliderEl.querySelector('.c-slider__container'), { ...baseOptions, ...additionalSettings})

      if (sliderEl.classList.contains('c-slider--offgrid')) {
        // Get to know which slides are currently in view
        slider.on('init slideChange', (s) => {
          let slidesPerView = s.passedParams.slidesPerView

          if (Object.keys(s.passedParams.breakpoints).includes(s.currentBreakpoint)) {
            slidesPerView = s.passedParams.breakpoints[s.currentBreakpoint].slidesPerView
          }

          const currentSlideSelection = Array.from(s.slides).slice(s.realIndex, s.realIndex + slidesPerView)
          const restSlides = Array.from(s.slides).filter(slide => !currentSlideSelection.includes(slide))
          const viewdSlides = Array.from(s.slides).filter((slide,index) => index < s.activeIndex)


          currentSlideSelection.forEach(slideEl => slideEl.classList.add(['c-slider__item--current']))
          currentSlideSelection.forEach(slideEl => slideEl.classList.remove(['c-slider__item--viewed']))
          restSlides.forEach(slideEl => slideEl.classList.remove('c-slider__item--current'))
          viewdSlides.forEach(slideEl => slideEl.classList.add('c-slider__item--viewed'))
        });
      }

      slider.on('slideChange', (s) => {
        eventBus.emit('motionbook-slide-changed', s.realIndex)
      })
    }

    const handleMobileSlider = () => {
      const mq = window.matchMedia('(min-width: 1024px)')

      if (mq.matches === true) {
        if (slider !== undefined) {
          slider.destroy(true, true);
        }
        sliderEl.classList.add('c-slider--is-grid');
      } else if (mq.matches === false) {
        enableSlider()
        slider.init()
        sliderEl.classList.remove('c-slider--is-grid');
      }
    }

    if (additionalSettings['mobileOnly'] === true) {
      handleMobileSlider()

      window.addEventListener('resize', _throttle(handleMobileSlider, 500))
    } else {
      enableSlider()
      slider.init()
    }

    const showSliderArrows = () => {
      const arrows = document.querySelector('.c-slider__controls');

      if(arrows) {
        arrows.addEventListener('mouseenter',() => {
          arrows.style.opacity = "1";
        });

        arrows.addEventListener('mouseleave',() => {
          arrows.style.opacity = "0";
        })
      }

    }

    showSliderArrows();



    const setNewLink = () => {
      // Funktion zum Aktualisieren der URL mit dem Hash basierend auf der aktuellen Slider-Seite
      function updateURLHash() {
        let index = 0;
        let currentPage = slider.activeIndex + 1; // Index der aktiven Seite im Slider

        if(currentPage > 0) {
          if(currentPage === 1 ) {
            window.location.hash = '';
            window.location.hash = 'page=' + currentPage; // Setzen des Hashes in der URL
          } else {
            window.location.hash = '';
            index = index + 2;
            window.location.hash = 'page=' + (index * currentPage - 2) + '-' + ((index * currentPage - 2) + 1); // Setzen des Hashes in der URL
          }
        }
      }

      // Event-Listener, um die URL beim Wechsel der Slider-Seite zu aktualisieren
      slider.on('slideChange', () => {
        updateURLHash(); // Aufruf der Funktion zum Aktualisieren des Hashes
      });

      /*document.addEventListener('DOMContentLoaded', () => {
        updateURLHash(); // Aufruf der Funktion zum Aktualisieren des Hashes
      });*/

      // Funktion zum Überprüfen des Hashes in der URL und Navigieren zur entsprechenden Slider-Seite
      const navigateToHash = () => {
        const hash = window.location.hash;

        if (hash) {
          const page = parseInt(hash.split('=')[1]); // Extrahieren der Seitenzahl aus dem Hash

          if(page === 1 || page === 2) {
            slider.slideTo(page - 1);
          } else {
            slider.slideTo(page - (page / 2));
          }
        }
      }

      slider.on('slideChange', () => {
        navigateToHash(); // Aufruf der Funktion zum Öffnen der Seite
      });

      document.addEventListener('DOMContentLoaded', () => {
        navigateToHash(); // Aufruf der Funktion zum Öffnen der Seite
      });
    }

    setNewLink();
  })
}