import lottie from 'lottie-web';
import scrollMonitor from 'scrollmonitor';
import inView from 'in-view';

const hover = () => {
    const playerElements = document.querySelectorAll('.u-lottie.u-lottie--hover');
    const mq = window.matchMedia('(min-width: 1024px)');


    if (playerElements) {
        Array.prototype.forEach.call(playerElements, element => {

            const player = lottie.loadAnimation({
                container: element,
                renderer: 'svg',
                //loop: mq.matches,
                loop: false,
                autoplay: false,
                animationData: JSON.parse(
                    element.getAttribute('data-lottie-animation'),
                ),
            });
            player.setSpeed(0.6);

            const elWatcher = scrollMonitor.create(element, { top: -200 });
            elWatcher.enterViewport(() => {
                player.play();
            });

            /*
            if(mq.matches !== true) {
                const elWatcher = scrollMonitor.create(element, { top: -200 });
                elWatcher.enterViewport(() => {
                    player.play();
                });

            } else {
                element.parentNode.parentNode.addEventListener('mouseenter', () => {
                    player.play();
                });

                element.parentNode.parentNode.addEventListener('mouseleave', () => {
                    player.stop();
                    player.goToAndStop(0);
                });

            }
            */
        });
    }
};

hover();


const scrollLottie = () => {
    const playerElm = document.querySelectorAll('.u-lottie.u-lottie--loop');

    if (playerElm) {
        const observerOptions = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5,
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const player = startLottieAnimation(entry.target);

                    if(entry.target.getAttribute('data-animation-delay') === 0) {
                        player.play(); // Starte die Animation nach dem Timeout
                        observer.unobserve(entry.target);
                    } else {
                        const timeout = entry.target.getAttribute('data-animation-delay');
                        setTimeout(() => {
                            player.play(); // Starte die Animation nach dem Timeout
                            observer.unobserve(entry.target);
                        }, timeout); // Timeout von 1 Minute (1 * 60 * 1000 = 60000 Millisekunden)
                    }
                }
            });
        }, observerOptions);

        Array.prototype.forEach.call(playerElm, (element) => {
            observer.observe(element);
        });
    }
};

const startLottieAnimation = (element) => {
    const player = lottie.loadAnimation({
        container: element,
        renderer: 'svg',
        loop: JSON.parse(
            element.getAttribute('data-animation-loop'),
        ),
        autoplay: false, // Setze autoplay auf false
        animationData: JSON.parse(element.getAttribute('data-lottie-animation')),
    });
    player.setSpeed(0.6);
    return player; // Gib den Player zurück
};

scrollLottie();