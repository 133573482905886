import SmoothScroll from 'smooth-scroll';

// remove hash in url
function removeHash () {
    history.pushState("", document.title, window.location.pathname
        + window.location.search);
}

const scroller = new SmoothScroll(null, {
    speed: 1500,
    offset: function (anchor, toggle) {
        // offset header height
        const header = document.querySelector('.c-header');
        if(header) {
            return header.clientHeight;
        } else {
            0
        }
    },
});

const scroll = () => {

    let hash = window.location.hash;

    if (hash.length) {
        hash = hash.replace('#',''); // replace id = id without a hashtag
        const section = document.getElementById(hash);

        if(section) {
            scroller.animateScroll(section); //scroll to section
        }
    }

};
scroll();

document.addEventListener('scrollStop', removeHash, false); //when scroll stops remove the hash in url